@import "sass-lib";
@media only screen and (max-width:767px){
	.lang{
	 	margin-right: 10px; margin-top: 20px; font-size: 11px;
	 	&:before{ width:2px; background: #ddd; height: 15px; content: ''; position: absolute; top:0; left: 18px;bottom:5px; margin: auto 0; }
	 	span{
			&:first-child{
				padding-right:0px; margin-right: 5px; width: 15px; overflow: hidden;
				&:before{ display:none; }
			}
			&:last-child{
				 width: 19px; overflow: hidden;
			}
		}
	}
	header.new{
		.left-header{
			img{ width: 200px; }
		}
		.line{
			&:before{
			width: 140px;
			}
		}
		.right-header{
			position: relative;
			img{ width: 40px; }
		}
	}

	.row_list{
	    .col{
	        &.col_ltl_full{width: 100%;}
	        &.col_ltl_8{width: 80%;}
	        &.col_ltl_7{width: 70%;}
	        &.col_ltl_6{width: 60%;}
	        &.col_ltl_55{width: 55%;}
	        &.col_ltl_5{width: 50%;}
	        &.col_ltl_45{width: 45%;}
	        &.col_ltl_4{width: 40%;}
	        &.col_ltl_35{width: 35%;}
	        &.col_ltl_33{width: 33.3333333333333%;}
	        &.col_ltl_3{width: 30%;}
	        &.col_ltl_25{width: 25%;}
	        &.col_ltl_2{width: 20%;}
	        &.col_ltl_16{width: 16.66666666666666%;}
	        &.col_ltl_1{width: 10%;}
	    }
	}
	.box-home.new{
		padding-top: 30px; display: flex; flex-direction: column;
		.list-box{
			width: 100%; float: none;display: block;
			&:first-child{ order:2; }
			&.reksaabs{
				position: relative;  left: auto; top: auto;right: auto;bottom: auto; margin-top: 60px; order:3;
				.btn-knj{
					position: relative;
				}
			}
			.reksa-home{ position: relative; left: auto; top: auto;right: auto;bottom: auto;}
			.wrap-dana.new{ position: relative;   left: auto; top: auto;right: auto;bottom: auto; height:400px;}
			&.loginabs{
				position: relative; left: auto; top: auto;right: auto;bottom: auto; order: 1; margin-bottom: 40px;
				.login.new{
					max-height: none; padding-bottom: 60px;
					.row{ 
						float: none; display: block; width: 100%;
						&:last-child{ padding-top:0; }
					}
					&.forgot.new{
						.form{ margin-bottom: 20px; }
						.row{
							width: 100%;
						}
						.right{
							width: 150px;
						}
					}
				}
			}

		}
	}
	.middle-content{
		.breadcrumb{
			padding-bottom: 10px; margin-bottom: 30px;
			.box-breadcrumb{
				width: 100%;
				a{
					font-size: 14px; width: 100%; height: 28px; line-height: 28px; overflow: hidden; display: inline-block; padding-right: 40px; position: relative; @include boxSizing(border-box);
					i{position: absolute; top: 0; right: 0;}
				}
			}
			.next-content{
				width: 100%; display: inline-block; margin-top: 10px;
			}
		}
		.wrap-tab-perform{
			.btn-htab{display: none;}
		    .box-selexbox-mb{
		    	display: block;
		    	select{width: 100%; height: 50px;}
		    }
		}
		.cost-notes{font-size: 13px;}
	}
	.list-tab-prod{width: 100%; overflow: auto;}
	.data-table-list{
		/*tbody{*/
			tr{
				th { min-width: auto !important; }
				th, td{
					&:nth-child(2){
						&:after{display: none;}
					}
					&.hide_mid{display: table-cell;}
					/*&:nth-child(n + 3){display: none;}*/
				}
				td{padding: 17px 15px;}
			}
		/*}*/
		&.fundhome{
			tr{
				th, td{
					&:first-child{width: 40%;}
	                &:nth-child(2){width: 15%;}
	                &:nth-child(3){width: 8%;}
	                &:nth-child(4){width: 8%;}
	                &:nth-child(5){width: 8%;}
	                &:nth-child(6){width: 7%;}
	                &:nth-child(7){width: 7%;}
	                &:nth-child(8){width: 7%;}
				}
			}
		}
		&.fundreturn{
			tr{
				th, td{
                    &:first-child{width: 32%;}
                    &:nth-child(2){width: 15%;}
                    &:nth-child(3){width: 8%;}
                    &:nth-child(4){width: 8%;}
                    &:nth-child(5){width: 8%;}
                    &:nth-child(6){width: 7%;}
                    &:nth-child(7){width: 7%;}
                    &:nth-child(8){width: 7%;}
                    &:nth-child(9){width: 7%;}
                }
			}
		}
		&.freeze-table{
			width: 900px;
			thead, tbody{
				tr{
					th, td{
	                	/*&:nth-child(n+4){display: none;}*/
					}
				}
			}
			.hide_mid{display: table-cell !important;}
		}
	}
	.banner-middle{
		.tittle-banner{
			width: auto;
			h4{ font-size: 12px; }
			span{ font-size: 28px; }
		}
		.images{ 
			height: 200px; 
			img{ height: 100%; object-fit: cover; }
		}
	}
}

@media only screen and (max-width:479px){
	
	header.new{
		.right-header{
			margin-top:20px;
			figure{ width: 30px; }
		}
		.left-header{
			img{ width: 150px; }
		}
	}
	.lang{
		margin-right: 10px; margin-top: 10px;
		&:before{ bottom:10px; }
	}

}