/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";

$c_global: #36a5b3;
@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin boxSizing($attr){
    box-sizing:  $attr;
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    -ms-box-sizing: $attr;
    -o-box-sizing: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin afterclear{
    &:after{ content:''; clear: both; display: block; }
}
@mixin fullfloat{
    width: 100%;
    float: left;
}
@mixin fullinline{
    width: 100%;
    display: inline-block;
}
select{
    background: url('../images/material/arrow-bottom.png') no-repeat right center; padding: 0 32px 0 10px;
    &.sel_blue{
        background-image: url('../images/material/arrow-bottom-blue.png'); font-weight: 700; color: #36a5b3; font-size: 18px; border: none; min-width: 160px;
    }
}
.input_file {
    position: relative; border: 1px solid #ccc;@include borderRadius(8px); background: url('../images/material/ico_inputfile.png') no-repeat 97% center;
    input[type="file"] {
        position: absolute; top: 0; left: 0; z-index: 1; opacity: 0; min-width: 100%;
        padding: 12px 0;cursor: pointer;
    }
    span {padding: 20px; display: inline-block; width: 100%; font-size: 16px; font-weight: 300;}
}
.btn-oval{font-size: 13px; color: #fff; font-weight: 900; background: $c_global; height: 34px; line-height: 34px; padding: 0 30px; @include borderRadius(30px); border: none; cursor: pointer;
    @include transition(0.2s all); display: inline-block;
    &:hover{background: #21646d; color: #fff;}
    &.padd-plus{padding-left: 50px; padding-right: 50px;}
    &.btn-fix{width: 170px; padding: 0;}
    &.btn-submit-finance{margin-right: 40px;}
    &.btn-auto{width: auto;}
}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.a-blue{color: $c_global;}
.center { margin: 0 auto; }
.fl { float: left; }
.fr { float: right; }

/* color global
----------------------------------------------------------------------------------------------*/
$c_111: #111;
$c_333: #333;
$c_666: #666;
$c_999: #999;
$c_ea: #eaeaea;
$blue-m: #63bdc8;


$lt:'Lato', sans-serif;
$ub:'Ubuntu', sans-serif;
$m:'Montserrat', sans-serif;